<div class="modal" [id]="id">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content tx-size-sm">
      <div class="modal-body tx-center pd-y-20 pd-x-20">
        <button aria-label="Close" class="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
        <i class="icon fas fa-exclamation-circle tx-80 tx-warning lh-1 mg-t-20 d-inline-block"></i>
        <h4 class="mg-b-20">Are you sure ?</h4>
        <p class="mg-b-20 mg-x-20">{{title}}</p>
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
