import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss']
})
export class AlertModalComponent implements OnInit {

  // Variables
  @Input() id: string;
  @Input() title: string;

  constructor() { }

  ngOnInit(): void {
  }

}
